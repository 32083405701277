import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import { createApp } from 'vue'

import App from './Login.vue'

const app = createApp(App)

app.mount('#app')
import "bootstrap/dist/js/bootstrap.js"
