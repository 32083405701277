<script setup>
import {ref} from 'vue'
import axios from 'axios';
const message = ref('');
const credentials = ref({
    username:'',
    password:''
})

const login = async ()=>{
    await axios.post('/login',credentials.value)
        .then((res)=>{
            window.location.href = "/";
        })
        .catch((err)=>{
            if(err.response){
                message.value = err.response.data.message;
            }
        });
}
</script>

<template lang="pug">
div.container-fluid
    div.d-flex.justify-content-center.mt-5
        div.card.p-3(style="width:400px")
            img.card-img-top.my-4(src="@/../public/mypartsmanagerLogo.svg")
            div.card-body.p-0
                div.alert.alert-danger(v-if="message")
                    | {{ message }}

                form
                    div.mb-3
                        label.form-label Username
                        input.form-control(type="text" v-model="credentials.username")
                    div.mb-3
                        label.form-label Password
                        input.form-control(type="password" v-model="credentials.password")
                    div.d-grid
                        button.btn.btn-primary(@click.prevent.stop="login()") Login
</template>

<style scoped>
img {
    filter: drop-shadow(0px 0px 11px rgba(255,255,255,1));
}
</style>
